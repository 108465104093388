
export default {
  /**接口请求的基础路径 */
  baseUrl: {
    // dev: 'http://localhost:8080/', // 开发路径
    dev: 'http://192.168.8.171:8080/', //手机端
    // dev:'https://57014j29o0.goho.co/',
    // pro: 'http://14.29.220.58:8088/' // 生产路径

    // pro:'https://pss.dsinfosys.com.cn/',
    // pro:'https://pss2d.pss-sh.com/',
    pro:'https://www.dsinfosys.com.cn/',
  },
};


