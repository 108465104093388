/****   request.js   ****/
// 导入axios
import axios from 'axios'
import config from '@/config/index';
import qs from 'qs'
import store from '../store/store_modular/index'
import router from '@/router';
import { Toast } from 'vant';
const baseUrl =
  process.env.NODE_ENV === 'development'
    ? config.baseUrl.dev
    : config.baseUrl.pro;

// let navigator1 = null
// if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
//   console.log('我进来了', navigator)// 手机端
//   navigator1 = true
// }

//1. 创建新的axios实例，
const service = axios.create({
  // 公共接口--
  baseURL: baseUrl,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 8 * 1000
})
//需要 Basic cGM6eGltcGxl
const urlAll = ['/parking/user/signInByCode']
const getToken = () => {
  const accessToken = localStorage.getItem('token');
  if (!accessToken) {
    return '';
  }
  return `Bearer ${accessToken}`;
}

// 2.请求拦截器
service.interceptors.request.use(config => {
  //GET传参序列化
  if (config.method === 'get') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2,不转换会显示为arr[]=1&arr[]=2
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  // return config;

  let token = localStorage.getItem('token') || ''
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
  config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
  config.headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, POST, DELETE, OPTIONS, PUT',
    // 'Access-Control-Allow-Headers' :'Keep-Alive,User-Agent,X-Requested-With,Cache-Control,Cookie,X-CSRF-TOKEN,Accept,Authorization,Content-Type,api-token,language,Origin,Api-Token,token',
    'Content-Type': 'application/json', //配置请求头
    'Access-Control-Allow-Headers': 'accept, content-type, origin,token',
    "token": token ? token : '',
    "Authorization": urlAll.indexOf(config.url) != -1 ? "Basic cGM6eGltcGxl" : getToken() != '' ? getToken() : '',
    "atwise-env-id": "6968b509a0174c029c2b7e97a4110c8d",
    // "host": 'pss.dsinfosys.com.cn'
  }
  //如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
  //const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
  //    if(token){
  //       config.params = {'token':token} //如果要求携带在参数中
  //       config.headers.token= token; //如果要求携带在请求头中
  //     }
  return config
}, error => {
  Promise.reject(error)
})


// 3.响应拦截器
service.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理，关闭loading等
  if(response.data.msg == '用户信息错误' && response.data.state == 'EF00046'){
    localStorage.clear()
    Toast('登录信息异常')
    router.replace("/");
  }
  return response
}, error => {
  /***** 接收到异常响应的处理开始 *****/
  console.log(error,'接收到异常响应的处理开始')
  if(error.code == "ECONNABORTED"){
    Toast(error.message)
  }
  if (error.response && error.response.status == 401) {
    localStorage.clear()
    Toast('登录过期')
    router.replace("/");
  }


  return Promise.resolve(error.response)
})
//4.导入文件
export default service
